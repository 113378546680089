import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth.guard';
import { MainComponent} from "./home/main.component";
import { ResetComponent} from "./reset/reset.component";
import { LogoutComponent } from './logout/logout.component';
import { ProjectdetailsComponent } from './project/projectdetails.component';
import { AddprojectComponent } from './project/addproject.component';
import { EditComponent } from './project/edit.component';
import { MediaComponent } from './project/media.component';
import { SearchComponent } from './project/search.component';
import { AllProjectsComponent } from './project/all-projects.component';
import { DonorsComponent } from './project/donors.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotComponent } from './forgot/forgot.component';
import { ThanksComponent } from './donation/thanks.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccountSettingComponent } from './user/account-setting.component';
import { MyProfileComponent } from './user/my-profile.component';
import { FundsComponent } from './user/funds.component';
import { StripeActivateComponent } from './user/stripe-activate.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy.component';
import { TermsComponent } from './pages/terms.component';
import { NotFoundComponent } from './404/not-found.component';
import { FundsViewComponent } from './funds-view/funds-view.component';

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'reset/:passcode', component: ResetComponent },
  { path: 'projects', component: AllProjectsComponent },
  { path: 'project/search/:query', component: SearchComponent },
  { path: 'project/donors/:slug', component: DonorsComponent },
  { path: 'addproject', component: AddprojectComponent, canActivate: [AuthGuard] },
  { path: 'project/edit/:slug', component: EditComponent, canActivate: [AuthGuard] },
  { path: 'project/media/:slug', component: MediaComponent, canActivate: [AuthGuard] },
  { path: 'project/:slug', component: ProjectdetailsComponent },
  { path: 'donation/thanks', component: ThanksComponent, canActivate: [AuthGuard] },
  { path: 'user/dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'user/account-setting', component: AccountSettingComponent, canActivate: [AuthGuard] },
  { path: 'user/my-profile', component: MyProfileComponent, canActivate: [AuthGuard] },
  { path: 'user/funds', component: FundsComponent, canActivate: [AuthGuard] },
  { path: 'user/stripe-activate', component: StripeActivateComponent, canActivate: [AuthGuard] },
  { path: 'project/funds/:slug', component: FundsViewComponent, canActivate: [AuthGuard] },
  { path: '404', component: NotFoundComponent},
  { path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
