import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import {Router,Event,NavigationStart, NavigationEnd, NavigationError} from "@angular/router";
import { MyAppSettings } from '../core/my.app.settings';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.css'],
})
export class HeaderComponent implements OnInit {
  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
	title = 'Header';
  headerClass = '';
	isLoggedIn: boolean = false;

  searchForm: FormGroup;
  submittedsearch = false;
  searchdata = '';

  user_data: any;
  
  constructor(private router: Router, private formBuilder: FormBuilder) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
      
      }
      
      if (event instanceof NavigationEnd) {
         
        if (window.localStorage.getItem('token')) {
            this.isLoggedIn = true;
            this.user_data = JSON.parse(window.localStorage.getItem('user_data'))            
        } else {
            this.isLoggedIn = false;
        }

        if(this.router.url == "/") {
           this.headerClass = '';

        } else {
           this.headerClass = 'inner-header';
        }
      }

      if (event instanceof NavigationError) {
          // Hide loading indicator

          // Present error to user
          console.log(event.error);
      }

    });

    this.createSearchForm();
  }

  createSearchForm() {
    this.searchForm = this.formBuilder.group({
      search: ['', [Validators.required]]
    });
  }

  onSubmitSearch() {
    this.submittedsearch = true;

    // stop here if form is invalid
    if (this.searchForm.invalid) {
        return;
    }
    
    this.searchdata = this.searchForm.controls.search.value

    this.router.navigate(['/project/search/'+this.searchdata]);
  }

  ngOnInit() {

  }
}