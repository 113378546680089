import {Component, OnInit } from '@angular/core';
import { MyAppSettings } from '../core/my.app.settings';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
})

export class TermsComponent implements OnInit {

  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;

  constructor() { }

  ngOnInit() {
    
  }
}