import { Component, OnInit } from '@angular/core';
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { MyAppSettings } from '../core/my.app.settings';

@Component({
  selector: 'app-punctual',
  templateUrl: './punctual.component.html',
  styleUrls: ['./home.component.css']
})

export class PunctualComponent implements OnInit {

  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
	projects: "loading...";

 	constructor(private apiService: ApiService, private toastr: ToastrService) { 
	}

 	punctualProjects() {
  	this.apiService.punctualProjects().subscribe(res => {
            
      if (res.success) {
        this.projects = res.data.campanionDetails; 
        console.log(this.projects)
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
    });
  }

	ngOnInit() {
    this.punctualProjects();
	}

  setMyStyles(rrrrrrrr) {
    let styles = {
      'width': rrrrrrrr + '%',
    };
    return styles;
  }
}