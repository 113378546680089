import { Component, OnInit } from '@angular/core';
import { ApiService } from "../core/api.service";
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { MyAppSettings } from '../core/my.app.settings';

@Component({
  selector: 'app-funds-view',
  templateUrl: './funds-view.component.html',
  styleUrls: ['./funds-view.component.css']
})
export class FundsViewComponent implements OnInit {

constructor(private apiService: ApiService, private router: Router, private toastr: ToastrService,  
    private SpinnerService: NgxSpinnerService) { 
	}

  ngOnInit() {
//    this.route.paramMap.subscribe(params => {
//       this.project_slug = params.get('slug');
// //      this.getProject(this.project_slug);
//     })

  }

    // getProject(slug) {
    //   this.SpinnerService.show();
    //   this.apiService.getProject(slug).subscribe(res => {
            
    //     if (res.success) {
    //       this.projectdata = res.data.campanionDetail;
    //       this.project_id = this.projectdata.id;
    //       this.project_doners = this.projectdata.project_doners;

    //       this.project_tags = '';
    //       for (var i = 0; i < this.projectdata.project_tags.length; i++) {
    //         this.project_tags += this.projectdata.project_tags[i].tag;
    //         if(i < this.projectdata.project_tags.length-1) {
    //           this.project_tags += ", ";
    //         }
    //       }

    //       if(this.projectdata.is_closed == "YES") {
    //         this.is_closed = true
    //       }

    //       if(this.projectdata.is_target_complete == "YES") {
    //         this.is_target_complete = true
    //       }

    //       this.error_msg = '';
    //       this.is_project_load = true;
    //       this.SpinnerService.hide();  
    //     }
    //   },
    //   error => {
    //     this.error_msg = error.error.message;
    //     this.is_project_load = false;
    //     this.SpinnerService.hide();
    //     this.toastr.error(error.error.message, 'Oops!!');
    //   });
    // }
    
  }