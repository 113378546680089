import { Component, OnInit } from '@angular/core';
import { ApiService } from "../core/api.service";
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { MyAppSettings } from '../core/my.app.settings';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
	projects: "loading...";
  project_not_found: boolean = false;
  closeClicked = false;
  redeemClicked = false;

	constructor(private apiService: ApiService, private router: Router, private toastr: ToastrService,  
    private SpinnerService: NgxSpinnerService) { 
	}

	userProjects() {
    this.SpinnerService.show();
		this.apiService.userProjects().subscribe(res => {
      if (res.success) {
        this.projects = res.data.campanionDetails; 
        this.SpinnerService.hide();  
      }
    },
    error => {
//      this.toastr.error(error.error.message, 'Oops!!');
      this.project_not_found = true;
      this.SpinnerService.hide();
    });
	}

  closeMyProject(id) {
    this.closeClicked = true;
    const registerPayload = {
      id: id
    }

    this.apiService.closeMyProject(registerPayload).subscribe(res => {
      if (res.success) {
        this.userProjects();
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
    });
    this.closeClicked = false;
  }

  redeemMyProject(id, tot_payment_received) {
    this.redeemClicked = true;
    const registerPayload = {
      id: id,
      tot_payment_received: tot_payment_received
    }

    this.apiService.redeemMyProject(registerPayload).subscribe(res => {
      if (res.success) {
        this.userProjects();
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
    });
    this.redeemClicked = false;
  }

	ngOnInit() {
		this.userProjects();
	}
}