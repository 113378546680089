import {Component, OnInit } from '@angular/core';
import { ApiService } from "../core/api.service";

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./home.component.css']
})

export class BlogComponent implements OnInit {

  html_content:any="";

  constructor(private apiService: ApiService) {   
  }

  getBlogHTML() {
  	this.apiService.getBlogHTML().subscribe(res => {
            
      if (res.html) {
        this.html_content = res.html; 
      }
    },
    error => {
      
    });
  }

  ngOnInit() {
    this.getBlogHTML();
  }
}
