import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { MyAppSettings } from '../core/my.app.settings';

@Component({
  selector: 'app-donors',
  templateUrl: './donors.component.html',
  styleUrls: []
})

export class DonorsComponent implements OnInit {

  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
  title = "Donors";
  projectslug = '';
  doners = [];
  constructor(private route: ActivatedRoute, private apiService: ApiService, private toastr: ToastrService) {

  }

  donorsProjects() {
  this.apiService.donorsProjects(this.projectslug).subscribe(res => {
      if (res.success) {
        this.doners = res.data.project_doners;
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
      this.doners = [];
    });
  }

  setMyStyles(rrrrrrrr) {
    let styles = {
      'width': rrrrrrrr + '%',
    };
    return styles;
  }
    
  ngOnInit() {
  	this.route.paramMap.subscribe(params => {
      this.projectslug = params.get('slug');
      this.donorsProjects();
    })
  }
}