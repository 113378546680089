import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { MyAppSettings } from '../core/my.app.settings';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: []
})

export class ForgotComponent implements OnInit {
  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
  title = 'Forgot Password';
  forgotForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private route: ActivatedRoute, private toastr: ToastrService) {
    if (window.localStorage.getItem('token')) {
      this.router.navigate(['/user/dashboard']);
    }
    this.createForm();
  }
    
  createForm() {
    this.forgotForm = this.formBuilder.group({
      email_address: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgotForm.invalid) {
      return;
    }
    const registerPayload = {
      email: this.forgotForm.controls.email_address.value
    }

    this.apiService.forgot(registerPayload).subscribe(res => {
           
      if (res.success) {
        this.toastr.info(res.message, 'Success!!');
        this.router.navigate(['/login']);
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
    });
  }

  ngOnInit() {

  }
}
