import { Component, OnInit } from '@angular/core';
import { MyAppSettings } from '../core/my.app.settings';
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { NgZone } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-find',
  templateUrl: './find.component.html',
  styleUrls: ['./home.component.css']
})

export class FindComponent implements OnInit {

  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;

  public user: SocialUser;
  social_connect_facebook = false;
  facebook_profile_image = '';
  facebook_profile_email = '';
  isLoggedIn: boolean = false;

  constructor(private apiService: ApiService, private authService: AuthService, private toastr: ToastrService, private ngZone:NgZone, private router: Router) { }

  connectWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(userInfo => {
      this.registerSocialUser(userInfo.id, userInfo.facebook.first_name, userInfo.facebook.last_name, userInfo.facebook.picture.data.url, userInfo.facebook.email, 'Facebook', userInfo.authToken);
      /*this.signOut();*/
    },
    error => {
      this.toastr.error(error, 'Oops!!');
    });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(userInfo => {
      console.log(userInfo);
      this.getDataFromAPI_with_register(userInfo.id, userInfo.facebook.first_name, userInfo.facebook.last_name, userInfo.facebook.picture.data.url, userInfo.facebook.email, 'Facebook', userInfo.authToken);
      /*this.signOut();*/
    },
    error => {
      this.toastr.error(error, 'Oops!!');
    });
  }

  getDataFromAPI_with_register(profileID, profileFirstName, profileLastName, profilegetImageUrl, profilegetEmail, plateform, authToken) {
    const formData = {
      first_name:profileFirstName,
      last_name:profileLastName,
      source:plateform,
      social_id: profileID,
      email:profilegetEmail,
      phone_number:"1111111111",
      imageurl:profilegetImageUrl,
      authToken:authToken
    }

    this.apiService.socialLogin(formData).subscribe(res => {
          if (res.success) {
            console.log(res);
            localStorage.setItem('user_data', JSON.stringify(res.data.user_data));
            localStorage.setItem('token', res.data.access_token);
            this.registerSocialUser(profileID, profileFirstName, profileLastName, profilegetImageUrl, profilegetEmail, plateform, authToken);
            this.ngZone.run(()=>this.router.navigate(['/user/dashboard']));
            this.toastr.success(res.message, 'Success!!');
          }
        },
        error => {
          this.toastr.error(error.error.message, 'Oops!!');
        });
  }

  signOut(): void {
    this.authService.signOut();
  }

  registerSocialUser(profileID, profileFirstName, profileLastName, profilegetImageUrl, profilegetEmail, plateform, authToken) {
    
    const formData = {
      social_email: profilegetEmail,
      social_site: plateform,
      social_id: profileID,
      profile_image_url: profilegetImageUrl,
      authToken: authToken
    }

    this.apiService.registerSocialUser(formData).subscribe(resulti => {
      if(resulti.success) {
        this.checkSocialConnectOrNot();
        this.toastr.success(resulti.message, 'Success!!');
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
    });
  }

  checkSocialConnectOrNot() {
    this.apiService.checkSocialConnectOrNot().subscribe(res => {
      if (res.success) {
        if(res.data.social_connect[0].social_site == "Facebook") {
          this.social_connect_facebook = true;
          this.facebook_profile_image = res.data.social_connect[0].profile_image_url;
          this.facebook_profile_email = res.data.social_connect[0].social_email;
        } else if(res.data.social_connect.length == 2 && res.data.social_connect[1].social_site == "Facebook") {
          this.social_connect_facebook = true;
          this.facebook_profile_image = res.data.social_connect[1].profile_image_url;
          this.facebook_profile_email = res.data.social_connect[1].social_email;
        } else {
          this.social_connect_facebook = false;
          this.facebook_profile_image = '';
          this.facebook_profile_email = '';
        }
      }
    },
    error => {
      this.social_connect_facebook = false;
      this.facebook_profile_image = '';
      this.facebook_profile_email = '';
    });
  }

  ngOnInit() {
    if (window.localStorage.getItem('token')) {
        this.isLoggedIn = true;
        this.checkSocialConnectOrNot();
    } else {
        this.isLoggedIn = false;
    }
  }
}