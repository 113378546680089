import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../core/api.service";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs/index";
import { ApiResponse } from "../model/api.response";
import { NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MyAppSettings } from '../core/my.app.settings';
import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
 
@Component({
  selector: 'app-sociallogin',
  templateUrl: './sociallogin.component.html',
  styleUrls: ['./sociallogin.component.css']
})
export class SocialloginComponent implements OnInit {

  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;

  baseUrl: string = 'http://xsdemo.com/hrm/backend/api/';

  public user: SocialUser;
 
  constructor(private router: Router, private apiService: ApiService, private route: ActivatedRoute, private http: HttpClient, private ngZone:NgZone, private toastr: ToastrService, private authService: AuthService) { }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(userInfo => {
      this.getDataFromAPI(userInfo.id, userInfo.firstName, userInfo.lastName, userInfo.photoUrl, userInfo.email, 'Google', userInfo.authToken);
      this.signOut();
    },
    error => {
      this.toastr.error(error, 'Oops!!');
    });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(userInfo => {
      this.getDataFromAPI(userInfo.id, userInfo.facebook.first_name, userInfo.facebook.last_name, userInfo.facebook.picture.data.url, userInfo.facebook.email, 'Facebook', userInfo.authToken);
      //this.signOut();
    },
    error => {
      this.toastr.error(error, 'Oops!!');
    });
  }

  signOut(): void {
    this.authService.signOut();
  }

  getDataFromAPI(profileID, profileFirstName, profileLastName, profilegetImageUrl, profilegetEmail, plateform, authToken) {
    const formData1 = {
      email: profilegetEmail,
      source:plateform,
      first_name:profileFirstName,
      last_name:profileLastName,
      imageurl:profilegetImageUrl,
      social_id: profileID,
      authToken: authToken
    }

    this.apiService.socialLogin(formData1).subscribe(res => {
      if (res.success) {
        console.log(res);
        localStorage.setItem('user_data', JSON.stringify(res.data.user_data));
        localStorage.setItem('token', res.data.access_token);
        this.ngZone.run(()=>this.router.navigate(['/user/dashboard']));
        this.toastr.success(res.message, 'Success!!');
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
    });
  }

  ngOnInit() {
  }
}