import { Component, OnInit } from '@angular/core';
import { MyAppSettings } from '../core/my.app.settings';


@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  //styleUrls: [''],
})
export class FooterComponent implements OnInit {
  
  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;

  constructor() { }

  ngOnInit() {

  }
}