import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { MyAppSettings } from '../core/my.app.settings';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;

  constructor( private router: Router ) { }

  ngOnInit() {
  	localStorage.clear();
  	this.router.navigate(['/']);
  }
}
