import {Component, OnInit } from '@angular/core';
import { MyAppSettings } from '../core/my.app.settings';
import * as $AB from 'jquery';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./home.component.css']
})

export class MainComponent implements OnInit {

  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
  videoURL = this.BACKEND_ENDPOINT+'/assets/front/videos/united-we-can-video.mp4'

  constructor() { }

  playVideo() {
	$('.video-modal-dialog1').on('shown.bs.modal', function () {
	var video_url = $(this).attr('data-href');
	
	$(this).find('video').attr('src',video_url);
	var myVideo: any = document.getElementById("abc_frame1");
      myVideo.play();
	})
  }

  closeVideo() {
	$('.video-modal-dialog1').on('hidden.bs.modal', function () {
	  $(this).find('video').attr('src','');
	})
  }

  playVideo2() {
	$('.video-modal-dialog1').on('shown.bs.modal', function () {
	var video_url = $(this).attr('data-href');
	
	$(this).find('video').attr('src',video_url);
	var myVideo: any = document.getElementById("abc_frame2");
      myVideo.play();
	})
  }

  closeVideo2() {
	$('.video-modal-dialog1').on('hidden.bs.modal', function () {
	  $(this).find('video').attr('src','');
	})
  }

  ngOnInit() {
    
  }
}
