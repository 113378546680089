import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { MyAppSettings } from '../core/my.app.settings';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-my-profile',
  templateUrl: 'my-profile.component.html',
  //styleUrls: [''],
})
export class MyProfileComponent implements OnInit {

  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;	
  user_profile_image: string;
  user_name: string;
  user_joined: string;

  raisedAmount: string;
  donatedAmount: string;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private toastr: ToastrService, private SpinnerService: NgxSpinnerService) {
  }

  getRaisedAndDonatedAmount() {
    this.SpinnerService.show();
    this.apiService.getRaisedAndDonatedAmount().subscribe(res => {
      if (res.success) {
        this.raisedAmount = res.data.raisedAmount; 
        this.donatedAmount = res.data.donatedAmount; 
        this.SpinnerService.hide();  
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
      this.SpinnerService.hide();  
    });
  }

  ngOnInit() {

  	var user_data = JSON.parse(window.localStorage.getItem('user_data'));

  	this.user_profile_image = user_data.profile_image;
  	this.user_name = user_data.first_name+ ' ' +user_data.last_name;
  	let joined = new Date(Date.parse(user_data.add_date.replace(/[-]/g,'/')));
  	this.user_joined = joined.getMonth()+1+'/'+joined.getDate()+'/'+joined.getFullYear();

    this.getRaisedAndDonatedAmount();
  }
}