import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthGuard} from './core/auth.guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptor } from "./core/interceptor";
import { ApiService } from "./core/api.service";
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";  
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { TagInputModule } from 'ngx-chips';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { HeaderComponent } from './common/header.component';
import { FooterComponent } from './common/footer.component';

// register controller
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ForgotComponent } from './forgot/forgot.component';
import { ResetComponent } from './reset/reset.component';
import { SocialloginComponent } from './login/sociallogin.component';

import { MainComponent } from './home/main.component';
import { FindComponent } from './home/find.component';
import { BlogComponent } from './home/blog.component';
import { PunctualComponent } from './home/punctual.component';
import { SubscriptionsComponent } from './home/subscriptions.component';
import { NewProjectsComponent } from './home/new-projects.component';
import { ProjectdetailsComponent } from './project/projectdetails.component';
import { SearchComponent } from './project/search.component';
import { AllProjectsComponent } from './project/all-projects.component';
import { DonorsComponent } from './project/donors.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { AddprojectComponent } from './project/addproject.component';
import { EditComponent } from './project/edit.component';
import { MediaComponent } from './project/media.component';
import { LogoutComponent } from './logout/logout.component';
import { ThanksComponent } from './donation/thanks.component';

import { AccountSettingComponent } from './user/account-setting.component';
import { MyProfileComponent } from './user/my-profile.component';
import { FundsComponent } from './user/funds.component';
import { StripeActivateComponent } from './user/stripe-activate.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy.component';
import { TermsComponent } from './pages/terms.component';
import { NotFoundComponent } from './404/not-found.component';

import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { FundsViewComponent } from './funds-view/funds-view.component';
let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("420734322275-sa03smc80abdhftefvu0t81ocndsshkq.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    //provider: new FacebookLoginProvider("260544101783043")
    provider: new FacebookLoginProvider("1218643341932206")
  }
]);
 
export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,

    // load common
    HeaderComponent,
    FooterComponent,
    MainComponent,
    FindComponent,
    BlogComponent,
    PunctualComponent,
    SubscriptionsComponent,
    NewProjectsComponent,
    ProjectdetailsComponent,
    SearchComponent,
    AllProjectsComponent,
    DonorsComponent,
    LoginComponent,
    RegisterComponent,
    ForgotComponent,
    ResetComponent,
    SocialloginComponent,
    DashboardComponent,
    LogoutComponent,
    AddprojectComponent,
    EditComponent,
    MediaComponent,
    ThanksComponent,
    AccountSettingComponent,
    MyProfileComponent,
    FundsComponent,
    StripeActivateComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    NotFoundComponent,
    FundsViewComponent
  ],

  imports: [
    BrowserModule, 
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule, 
    NgxSpinnerModule,
    TagInputModule,
    CKEditorModule,
    SocialLoginModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),    
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    })
  ],

  providers: [
    ApiService,
    AuthGuard,
    {provide: AuthServiceConfig, useFactory: provideConfig},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi : true},
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
