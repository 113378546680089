import { Component } from '@angular/core';
import {Router,Event,NavigationStart, NavigationEnd, NavigationError} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ClearGives';
  isLoggedIn: boolean = false;
  currentURL: string;

  constructor(private router: Router) {
    router.events.subscribe((event: Event) => {

      if (event instanceof NavigationStart) {

      }
      if (event instanceof NavigationEnd) {
               
        if (window.localStorage.getItem('token')) {
          this.isLoggedIn = true;
                    
        } else {
          this.isLoggedIn = false;
        }
      }

      if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
        console.log(event.error);
      }
    });
  }
}
