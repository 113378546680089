import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { MyAppSettings } from '../core/my.app.settings';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "angularx-social-login";
import { NgZone } from '@angular/core';
import { FacebookLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import Swal from 'sweetalert2';
import * as $AB from 'jquery';
@Component({
  selector: 'app-account-setting',
  templateUrl: 'account-setting.component.html',
  //styleUrls: [''],
})
export class AccountSettingComponent implements OnInit {

  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
  user_profile_image: string;
  user_name: string;
  org_logo_current: string;

  email_notifications_id: any
  you_donate: boolean;
  new_donate: boolean;
  close_campaign: boolean;
  redeem_campaign: boolean;
  new_fundraiser: boolean;
  supports_a_campaign: boolean;
  funds_raised_25: boolean;
  funds_raised_50: boolean;
  funds_raised_75: boolean;
  before_event_starts: boolean;
  launch_a_campaign: boolean;
  you_make_a_donation: boolean;
  stripe: boolean;
  you_become_a_fundraiser: boolean;

  myFilesProfileImage:string = '';
  profileImageForm: FormGroup;
  accountSettingForm: FormGroup;
  submitted = false;

  myFilesOrgLogo:string = '';

  public user: SocialUser;
  social_connect_facebook = false;
  social_connect_twitter = false;
  facebook_profile_image = this.BACKEND_ENDPOINT+'/assets/images/dummy-user-img.jpg';
  twitter_profile_image = this.BACKEND_ENDPOINT+'/assets/images/dummy-user-img.jpg';


  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private toastr: ToastrService, private SpinnerService: NgxSpinnerService, private authService: AuthService, private ngZone:NgZone) {
    this.createProfileImageForm();
    this.createAccountSettingForm();
  }

  connectWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(userInfo => {
      console.log(userInfo);
      this.getDataFromAPI(userInfo.id, userInfo.facebook.first_name, userInfo.facebook.last_name, userInfo.facebook.picture.data.url, userInfo.facebook.email, 'Facebook', userInfo.authToken);
      /*this.signOut();*/
    },
    error => {
      this.toastr.error(error, 'Oops!!');
    });
  }

  disconnectSocialUser(plateform): void {
    this.apiService.deleteSocialUser(plateform).subscribe(resulti => {
      if(resulti.success) {
        this.checkSocialConnectOrNot();
        this.toastr.success(resulti.message, 'Success!!');
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
    });
  }

  signOut(): void {
    this.authService.signOut();
  }

  getDataFromAPI(profileID, profileFirstName, profileLastName, profilegetImageUrl, profilegetEmail, plateform, authToken) {
    
    const formData = {
      social_email: profilegetEmail,
      social_site: plateform,
      social_id: profileID,
      profile_image_url: profilegetImageUrl,
      authToken: authToken
    }

    this.apiService.registerSocialUser(formData).subscribe(resulti => {
      if(resulti.success) {
        this.checkSocialConnectOrNot();
        this.toastr.success(resulti.message, 'Success!!');
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
    });
  }

  checkSocialConnectOrNot() {
    this.apiService.checkSocialConnectOrNot().subscribe(res => {
      if (res.success) {
        if(res.data.social_connect[0].social_site == "Facebook") {
          this.social_connect_facebook = true;
          this.facebook_profile_image = res.data.social_connect[0].profile_image_url;
        } else if(res.data.social_connect.length == 2 && res.data.social_connect[1].social_site == "Facebook") {
          this.social_connect_facebook = true;
          this.facebook_profile_image = res.data.social_connect[1].profile_image_url;
        } else {
          this.social_connect_facebook = false;
          this.facebook_profile_image = this.BACKEND_ENDPOINT+'/assets/images/dummy-user-img.jpg';
        }

        if(res.data.social_connect[0].social_site == "Twitter") {
          this.social_connect_twitter = true;
          this.twitter_profile_image = res.data.social_connect[0].profile_image_url;
        } else if(res.data.social_connect.length == 2 && res.data.social_connect[1].social_site == "Twitter") {
          this.social_connect_twitter = true;
          this.twitter_profile_image = res.data.social_connect[1].profile_image_url;
        } else {
          this.social_connect_twitter = false;
          this.twitter_profile_image = this.BACKEND_ENDPOINT+'/assets/images/dummy-user-img.jpg';
        }
      }
    },
    error => {
      this.social_connect_facebook = false;
      this.social_connect_twitter = false;
      this.facebook_profile_image = this.BACKEND_ENDPOINT+'/assets/images/dummy-user-img.jpg';
      this.twitter_profile_image = this.BACKEND_ENDPOINT+'/assets/images/dummy-user-img.jpg';
    });
  }

  showDialog(event) {
    event.preventDefault();
    Swal.fire({
      title: 'Are you sure to delete?',
      text: 'You will not be able to recover this account!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.apiService.toDeleteAccount().subscribe(res => {
          if (res.success) {
            this.toastr.success(res.message, 'Success!!');
            localStorage.clear();
            this.router.navigate(['/']);
          }
        },
        error => {
          this.toastr.error(error.error.message, 'Oops!!');
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.toastr.error('Your Data are Safe', 'Cancelled');
      }
    })
  }

  createAccountSettingForm() {

    let numericRegex = /^[0-9]+$/;
    
    this.accountSettingForm = this.formBuilder.group({
      first_name: ['', Validators.required ],
      last_name: [''],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', [Validators.required, Validators.pattern(numericRegex)]],
      zip: ['', Validators.required ],
      city: ['', Validators.required ],
      state: ['', Validators.required ],
      
      org_name: ['', Validators.required ],
      org_fb_profile: ['', Validators.required ],
      org_logo: ['', Validators.required ]
    });
  }

  onUpdateAccountSettingForm() {
  
    this.submitted = true;

    // stop here if form is invalid
    if (this.accountSettingForm.invalid) {
      return;
    }

    const formData = new FormData();

    formData.append("first_name", this.accountSettingForm.controls.first_name.value);
    formData.append("last_name", this.accountSettingForm.controls.last_name.value);
    formData.append("email", this.accountSettingForm.controls.email.value);
    formData.append("phone_number", this.accountSettingForm.controls.phone_number.value);
    formData.append("zip", this.accountSettingForm.controls.zip.value);
    formData.append("city", this.accountSettingForm.controls.city.value);
    formData.append("state", this.accountSettingForm.controls.state.value);

    formData.append("org_name", this.accountSettingForm.controls.org_name.value);
    formData.append("org_fb_profile", this.accountSettingForm.controls.org_fb_profile.value);
    formData.append('org_logo', this.myFilesOrgLogo);

    this.apiService.onUpdateAccountSetting(formData).subscribe(res => {    
      if (res.success) {
        this.toastr.success(res.message, 'Success!!');
        this.getLogInUser();
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
    });
  }

  createProfileImageForm() {
    this.profileImageForm = this.formBuilder.group({
      profileImage: ['', Validators.required ],
    });
  }

  onFileChangeOrgLogo(event) {
    this.myFilesOrgLogo = '';
    this.myFilesOrgLogo = event.target.files[0];
  }

  onUpdateProfileImage(event) {
    this.SpinnerService.show();
    this.myFilesProfileImage = '';
    this.myFilesProfileImage = event.target.files[0];

    const formData = new FormData();
    formData.append('profile_data', 'test');
    formData.append('profile_image', this.myFilesProfileImage);

    this.apiService.onUpdateProfileImage(formData).subscribe(res => {    
      if (res.success) {
        this.SpinnerService.hide();
        this.toastr.success(res.message, 'Success!!');
        var user_data = JSON.parse(window.localStorage.getItem('user_data'))
        user_data.profile_image = res.data.profile_image;
        localStorage.setItem('user_data', JSON.stringify(user_data));
        this.user_profile_image = res.data.profile_image;
        $('#pimage').attr('src',res.data.profile_image);
        this.router.navigate(['/user/account-setting']);
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
      this.SpinnerService.hide();  
    });

  }

  getLogInUser() {
    this.SpinnerService.show();
    this.apiService.getLogInUser().subscribe(res => {
      if (res.success) {
        let userDetails = res.data.userDetails; 
        this.user_profile_image = userDetails.profile_image;
        this.user_name = userDetails.first_name+' '+userDetails.last_name;

        let orgnisationDetails = res.data.orgnisationDetails;
        let org_name_current = ''
        let org_fb_profile_current = ''
        if(orgnisationDetails) {
          this.org_logo_current = orgnisationDetails.org_logo;
          org_name_current = orgnisationDetails.org_name;
          org_fb_profile_current = orgnisationDetails.org_fb_profile;
        } else {
          this.org_logo_current = '';
          org_name_current = '';
          org_fb_profile_current = '';
        }

        this.accountSettingForm.patchValue({
          first_name:userDetails.first_name,
          stripe:userDetails.stripe,
          last_name: userDetails.last_name,
          email: userDetails.email,
          phone_number: userDetails.phone_number,
          zip: userDetails.zip,
          city: userDetails.city,
          state: userDetails.state,

          org_name: org_name_current,
          org_fb_profile: org_fb_profile_current,
        });
        this.SpinnerService.hide();  
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
      this.SpinnerService.hide();  
    });
  }

  getEmailNotifications() {
    this.apiService.getEmailNotifications().subscribe(res => {
      if (res.success) {
        let email_notifications     = res.data.email_notifications; 
        this.email_notifications_id = email_notifications.id;
        this.you_donate             = (email_notifications.you_donate == 'YES')?true:false;
        this.new_donate             = (email_notifications.new_donate == 'YES')?true:false;
        this.close_campaign         = (email_notifications.close_campaign == 'YES')?true:false;
        this.redeem_campaign        = (email_notifications.redeem_campaign == 'YES')?true:false;
        this.new_fundraiser         = (email_notifications.new_fundraiser == 'YES')?true:false;
        this.supports_a_campaign    = (email_notifications.supports_a_campaign == 'YES')?true:false;
        this.funds_raised_25        = (email_notifications.funds_raised_25 == 'YES')?true:false;
        this.funds_raised_50        = (email_notifications.funds_raised_50 == 'YES')?true:false;
        this.funds_raised_75        = (email_notifications.funds_raised_75 == 'YES')?true:false;
        this.before_event_starts    = (email_notifications.before_event_starts == 'YES')?true:false;
        this.launch_a_campaign      = (email_notifications.launch_a_campaign == 'YES')?true:false;
        this.you_make_a_donation    = (email_notifications.you_make_a_donation == 'YES')?true:false;
        this.you_become_a_fundraiser= (email_notifications.you_become_a_fundraiser == 'YES')?true:false;
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
    });
  }

  changeNotificationsEmail(noti_field, event) {

    if(noti_field == "you_make_a_donation" || noti_field == "you_become_a_fundraiser") {
      event.target.checked = false;
      this.toastr.error('We are Working on this feature', 'Feature Not Available!!');
      return;
    }
    this.SpinnerService.show();
    var noti_value = (event.target.checked)?"YES":"NO";
    const registerPayload = {
        noti_value: noti_value,
        id: this.email_notifications_id,
        field: noti_field
    }

    this.apiService.changeNotificationsEmail(registerPayload).subscribe(res => {
      if (res.success) {
        this.getEmailNotifications();
        this.toastr.success(res.message, 'Success!!');
        this.SpinnerService.hide();
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
      this.SpinnerService.hide();
    });
  }

  ngOnInit() {
    this.getLogInUser();
    this.getEmailNotifications();
    this.checkSocialConnectOrNot();
  }
}