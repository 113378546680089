import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { MyAppSettings } from '../core/my.app.settings';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-stripe-activate',
  templateUrl: 'stripe-activate.component.html',
  //styleUrls: [''],
})
export class StripeActivateComponent implements OnInit {

  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
  redirect_uri = this.BACKEND_ENDPOINT+'user/stripe-activate';
//  redirect_uri = 'http://localhost:4200/user/stripe-activate';
  is_stripe_connect: boolean = false;
  stripe_data : any = null;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private route: ActivatedRoute, private toastr: ToastrService, private SpinnerService: NgxSpinnerService) {
  }

  ngOnInit() {

  	this.getStripeConnect();

  	this.route.queryParams.subscribe(params => {
      if(params.code && params.scope) {
	    this.stripeConnect(params);
	  } else if(params.error) {
	  	this.toastr.error(params.error, 'Oops!!');
	  } else {
	  	this.router.navigate(['/user/stripe-activate']);
	  }
    });
  }

  stripeConnect(params) {
  	this.SpinnerService.show();
    this.apiService.stripeConnect(params).subscribe(res => {        
      if (res.success) {
      	this.toastr.success(res.message, 'Success!!');
        this.SpinnerService.hide();
      	this.router.navigate(['/user/account-setting']);
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
      this.SpinnerService.hide();
    });
  }

  stripeDisconnect() {
  	this.SpinnerService.show();
  	this.apiService.stripeDisconnect().subscribe(res => {
      if (res.success) {
      	this.getStripeConnect();
        this.toastr.success(res.message, 'Success!!');
        this.SpinnerService.hide();
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
      this.SpinnerService.hide();  
    });
  }

  getStripeConnect() {
  	this.SpinnerService.show();
    this.apiService.getStripeConnect().subscribe(res => {
      if (res.success) {
        this.is_stripe_connect = true;
        this.stripe_data = res.data;
        this.SpinnerService.hide();  
      }
    },
    error => {
      this.is_stripe_connect = false;
      this.SpinnerService.hide();  
    });
  }
}