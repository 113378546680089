import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { MyAppSettings } from '../core/my.app.settings';

@Component({
  selector: 'app-funds',
  templateUrl: 'funds.component.html',
  //styleUrls: [''],
})
export class FundsComponent implements OnInit {
  
  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
  user_projects: any;
  donations: any;
  withdrawals: "loading...";

  offlineDonationForm: FormGroup;
  submitteddonation = false;
  donationTypes: any = ['Cash', 'Cheque', 'Pledge']
  countryList: any = ['United States', 'Canada', 'United Kingdom', 'Australia', 'Mexico', 'France']
  public minDate1 = new Date();

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private route: ActivatedRoute, private toastr: ToastrService, private SpinnerService: NgxSpinnerService) {
    this.createDonationForm();
  }

  createDonationForm() {
    let numericRegex = /^[1-9]\d*$/;
    this.offlineDonationForm = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: [''],
      email: ['', [Validators.required, Validators.email]],
      street: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      country: ['', [Validators.required]],
      notes: ['', [Validators.required]],
      projectid: ['', [Validators.required]],
      donationAMT: ['', [Validators.required, Validators.pattern(numericRegex)]],
      type: ['', [Validators.required]],
      payment_date: ['', [Validators.required]],
      make_donor_anonymous:[]
    });
  }

  onChangeAnonymousTick(event) {
    const checked = event.target.checked;  
    if (checked) {
      this.offlineDonationForm.patchValue({make_donor_anonymous:true});
    } else {
      this.offlineDonationForm.patchValue({make_donor_anonymous:false});
    }
  }

  onSubmitDonation() {
    this.submitteddonation = true;

    // stop here if form is invalid
    if (this.offlineDonationForm.invalid) {
        return;
    }

    $("#add-offline-donation-modal").modal("hide");

    const formData = new FormData();

    formData.append("first_name", this.offlineDonationForm.controls.first_name.value);
    formData.append("last_name", this.offlineDonationForm.controls.last_name.value);
    formData.append("email", this.offlineDonationForm.controls.email.value);
    formData.append("street", this.offlineDonationForm.controls.street.value);
    formData.append("city", this.offlineDonationForm.controls.city.value);
    formData.append("state", this.offlineDonationForm.controls.state.value);
    formData.append("zip", this.offlineDonationForm.controls.zip.value);
    formData.append("country", this.offlineDonationForm.controls.country.value);
    formData.append("notes", this.offlineDonationForm.controls.notes.value);
    formData.append("projectid", this.offlineDonationForm.controls.projectid.value);
    formData.append("donationAMT", this.offlineDonationForm.controls.donationAMT.value);
    formData.append("type", this.offlineDonationForm.controls.type.value);
    formData.append("payment_date", this.offlineDonationForm.controls.payment_date.value);
    formData.append("make_donor_anonymous", this.offlineDonationForm.controls.make_donor_anonymous.value);
    this.apiService.addOfflineDonationForm(formData).subscribe(res => {    
      if (res.success) {
        this.toastr.success(res.message, 'Success!!');
        this.router.navigate(['/user/dashboard']);
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
    });
  }

  userProjects() {
    this.apiService.userProjects().subscribe(res => {      
      if (res.success) {
        /*for (const property in res.data.campanionDetails) {
          this.user_projects[] = res.data.campanionDetails[property].title
        }*/
        this.user_projects = res.data.campanionDetails
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
    });
  }

  allDonationByUser() {
    this.SpinnerService.show();
  	this.apiService.allDonationByUser().subscribe(res => {
      if (res.success) {
        this.SpinnerService.hide();
        this.donations = res.data.paymentRecords; 
      }
    },
    error => {
    //  this.toastr.error(error.error.message, 'Oops!!');
      this.SpinnerService.hide();
    });
  }

  convertToCSV(objArray) {
      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';

      for (var i = 0; i < array.length; i++) {
          var line = '';
          for (var index in array[i]) {
              if (line != '') line += ','

              line += array[i][index];
          }

          str += line + '\r\n';
      }

      return str;
  }

  exportCSVFile(headers, items, fileTitle) {
      if (headers) {
          items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
              // Browsers that support HTML5 download attribute
              var url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", exportedFilenmae);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          }
      }
  }

  download(event){
    event.preventDefault();
    var headers = {
        srno: 'SR.No.', // remove commas to avoid errors
        payment_date: 'Payment Date', // remove commas to avoid errors
        first_name: "Name",
        email: "Email",
        amount: "Amount"
    };

    var itemsNotFormatted = this.donations;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item, iii) => {
        itemsFormatted.push({
            srno: iii+1, // remove commas to avoid errors,
            model: item.payment_date.replace(/,/g, ''), // remove commas to avoid errors,
            chargers: item.first_name.replace(/,/g, '')+item.last_name.replace(/,/g, ''),
            cases: item.email.replace(/,/g, ''),
            earphones: item.amount
        });
    });

    var fileTitle = 'donations'; // or 'my-unique-title'

    this.exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  allWithdrawalByUser() {
    this.apiService.allWithdrawalByUser().subscribe(res => {
      if (res.success) {
        this.withdrawals = res.data.redeemRecords; 
      }
    },
    error => {
     // this.toastr.error(error.error.message, 'Oops!!');
    });
  }

  ngOnInit() {
    this.userProjects();
	  this.allDonationByUser();
    this.allWithdrawalByUser();
  }
}