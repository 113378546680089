import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { MyAppSettings } from '../core/my.app.settings';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: []
})

export class ThanksComponent implements OnInit {

  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
  res : any = null;
  status : boolean = false;
  token : any;
  payment_from : string; 
  amount : number;
  
  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private route: ActivatedRoute, private toastr: ToastrService) {

  }
    
  ngOnInit() {

    if (window.localStorage.getItem('payment_success') == "true") {

      this.res = JSON.parse(window.localStorage.getItem('payment_data'));
      this.payment_from = window.localStorage.getItem('payment_from');
      this.status = true;
      this.token = this.res.token;
      this.amount = this.res.amount;

      localStorage.removeItem("payment_success");
      localStorage.removeItem("payment_data");
      localStorage.removeItem("payment_from");

    } else if (window.localStorage.getItem('payment_success') == "false") {
      localStorage.removeItem("payment_success");
    } else {
      this.router.navigate(['/']);
    }
  }
}
