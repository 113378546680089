import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, 
RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
    
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) 
  {
    if (window.localStorage.getItem('token')) {
      /*if(route.url[0].path=="login" || route.url[0].path=="register" || route.url[0].path=="forgot" || route.url[0].path=="reset") {
        this.router.navigate(['/user/dashboard']);
        return false;
      }*/
      // logged in so return true 
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}