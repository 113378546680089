import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { MyAppSettings } from '../core/my.app.settings';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./addproject.component.css']
})

export class EditComponent implements OnInit {

  error_msg = 'Loading...';
  is_project_load: boolean = false;
  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
  projects: "loading...";
  editProjectForm: FormGroup;
  submitted = false;
  islodder:boolean=false;
  isrightb:boolean=true;
  project_slug = null;
  projectdata = null;
  project_tags = [];
  setlocation = 'United States';

  user_profile_image: string;
  user_name: string;
  locationList: any = ['United States', 'Canada', 'United Kingdom', 'Australia', 'Mexico', 'France', 'Sao Paulo-SP']

  public Editor = ClassicEditor;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private route: ActivatedRoute, private toastr: ToastrService, private SpinnerService: NgxSpinnerService) {
    this.createeditProjectForm();
  }

  createeditProjectForm() {

    let numericRegex = /^[0-9]+$/;
    
    this.editProjectForm = this.formBuilder.group({
      title: ['', Validators.required ],
      description: ['', Validators.required ],
      location: [''],
      fund: ['', [Validators.required, Validators.pattern(numericRegex)]],
      project_tags: [''],
      start_date: ['', Validators.required ],
      last_date: ['', Validators.required ]
    }, {validator: this.dateLessThan('start_date', 'last_date')});
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): {[key: string]: any} => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (f.value > t.value) {
        return {
          dates: "Start Date should be less than Last Date"
        };
      }
      return {};
    }
  }

  onSubmit() {
  
    this.submitted = true;

    // stop here if form is invalid
    if (this.editProjectForm.invalid) {
      return;
    }
    this.islodder = true;
    this.isrightb = false;
    const formData = new FormData();

    formData.append("slug", this.project_slug);
    formData.append("title", this.editProjectForm.controls.title.value);
    formData.append("description", this.editProjectForm.controls.description.value);
    formData.append("location", this.editProjectForm.controls.location.value);
    formData.append("fund", this.editProjectForm.controls.fund.value);
    formData.append("project_tags", this.editProjectForm.controls.project_tags.value);
    formData.append("start_date", this.editProjectForm.controls.start_date.value);
    formData.append("last_date", this.editProjectForm.controls.last_date.value);
    //console.log()
    this.apiService.editProject(formData).subscribe(res => {    
      if (res.success) {
        this.router.navigate(['/user/dashboard']);
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
    });
  }

  getProject(slug) {
    this.SpinnerService.show();
    this.apiService.getProject(slug).subscribe(res => {
          
      if (res.success) {
        this.projectdata = res.data.campanionDetail;

        for (var i = 0; i < this.projectdata.project_tags.length; i++) {
          this.project_tags.push(this.projectdata.project_tags[i].tag);
        }

        this.setlocation = this.projectdata.location;

        this.editProjectForm.patchValue({
          title: this.projectdata.title,
          description: this.projectdata.description,
          fund: this.projectdata.fund,
          project_tags: this.project_tags,
          start_date: new Date(Date.parse(this.projectdata.start_date.replace(/-/g, '/'))),
          last_date: new Date(Date.parse(this.projectdata.last_date.replace(/-/g, '/')))
        });
        //this.editProjectForm.invalid = true;
        this.error_msg = '';
        this.is_project_load = true;
        this.SpinnerService.hide();  
      }
    },
    error => {
      this.error_msg = error.error.message;
      this.is_project_load = false;
      this.SpinnerService.hide();
      this.toastr.error(error.error.message, 'Oops!!');
                this.islodder = false;
    this.isrightb = true;
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.project_slug = params.get('slug');
      this.getProject(this.project_slug);
    })

    var user_data = JSON.parse(window.localStorage.getItem('user_data'));
    this.user_profile_image = user_data.profile_image;
    this.user_name = user_data.first_name;
  }
}