import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { MyAppSettings } from '../core/my.app.settings';
import * as bootstrap from "bootstrap";
import * as $AB from 'jquery';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: []
})

export class LoginComponent implements OnInit {
  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
  title = 'Login';
  loginForm: FormGroup;
  submittedlogin = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private route: ActivatedRoute, private toastr: ToastrService) {
    if (window.localStorage.getItem('token')) {
      this.router.navigate(['/user/dashboard']);
    }
    
    this.createFormLogin();
  }
    
  createFormLogin() {
    this.loginForm = this.formBuilder.group({
      email_address: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  onSubmitLogin() {
    this.submittedlogin = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    const registerPayload = {
        email: this.loginForm.controls.email_address.value,
        password: this.loginForm.controls.password.value,
        source:"Email"
    }

    this.apiService.login(registerPayload).subscribe(res => {
        
        if (res.success) {
            console.log(res);
            localStorage.setItem('user_data', JSON.stringify(res.data.user_data));
            localStorage.setItem('token', res.data.access_token);
            this.router.navigate(['/user/dashboard']);
            this.toastr.success(res.message, 'Success!!');
        }
    },
    error => {
        this.toastr.error(error.error.message, 'Oops!!');
    });
  }

  ngOnInit() {

  }
}