import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { MyAppSettings } from '../core/my.app.settings';

@Component({
  selector: 'app-all-projects',
  templateUrl: './all-projects.component.html',
  styleUrls: []
})

export class AllProjectsComponent implements OnInit {

  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
  projects = [];
  constructor(private route: ActivatedRoute, private apiService: ApiService, private toastr: ToastrService, private SpinnerService: NgxSpinnerService) {

  }

  getAllProjects() {
    this.SpinnerService.show();
    this.apiService.getAllProjects().subscribe(res => {
      if (res.success) {
        this.projects = res.data.campanionDetails;
        this.SpinnerService.hide();
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
      this.projects = [];
      this.SpinnerService.hide();
    });
  }

  setMyStyles(rrrrrrrr) {
    let styles = {
      'width': rrrrrrrr + '%',
    };
    return styles;
  }
    
  ngOnInit() {
    this.getAllProjects();
  }
}