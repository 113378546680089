import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { MyAppSettings } from '../core/my.app.settings';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-addproject',
  templateUrl: './addproject.component.html',
  styleUrls: ['./addproject.component.css']
})

export class AddprojectComponent implements OnInit {

  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
	projects: "loading...";
  addProjectForm: FormGroup;
  submitted = false;
  file = '';
  path:any;
  isImage:boolean=false;
  islodder:boolean=false;
  isrightb:boolean=true;

  myFiles:string [] = [];

  public minDate1 = new Date();
  public minDate2 = new Date();

  user_profile_image: string;
  user_name: string;
  locationList: any = ['United States', 'Canada', 'United Kingdom', 'Australia', 'Mexico', 'France', 'Sao Paulo-SP']

  public Editor = ClassicEditor;

	constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private route: ActivatedRoute, private toastr: ToastrService) {
    this.createAddProjectForm();
	}

  createAddProjectForm() {

    let numericRegex = /^[0-9]+$/;
    
    this.addProjectForm = this.formBuilder.group({
      title: ['', Validators.required ],
      description: ['', Validators.required ],
      location: ['', Validators.required ],
      fund: ['', [Validators.required, Validators.pattern(numericRegex)]],
      project_tags: ['', Validators.required ],
      start_date: ['', Validators.required ],
      last_date: ['', Validators.required ],
      image_name: ['', Validators.required ]
    }, {validator: this.dateLessThan('start_date', 'last_date')});
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): {[key: string]: any} => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (f.value > t.value) {
        return {
          dates: "Start Date should be less than Last Date"
        };
      }
      return {};
    }
  }

  onFileChange(event) {

  	this.myFiles = [];

  	for (var i = 0; i < event.target.files.length; i++) { 
      this.myFiles.push(event.target.files[i]);
    }

    /*if (event.target.files && event.target.files.length) {
      //console.log(URL.createObjectURL(event.target.files[0]));
      const [file] = event.target.files;
      this.file = file
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); 
      reader.onload = (_event) => { 
        this.path = reader.result;
      }
    }*/
  }

  onSubmit() {
  
    this.submitted = true;

    // stop here if form is invalid
    if (this.addProjectForm.invalid) {
      return;
    }

    this.isImage = true;
    this.islodder = true;
    this.isrightb = false;

    const formData = new FormData();

    formData.append("title", this.addProjectForm.controls.title.value);
    formData.append("description", this.addProjectForm.controls.description.value);
    formData.append("location", this.addProjectForm.controls.location.value);
    formData.append("fund", this.addProjectForm.controls.fund.value);
    formData.append("project_tags", this.addProjectForm.controls.project_tags.value);
    formData.append("start_date", this.addProjectForm.controls.start_date.value);
    formData.append("last_date", this.addProjectForm.controls.last_date.value);

    for (var i = 0; i < this.myFiles.length; i++) { 
      formData.append("image_name[]", this.myFiles[i]);
    }

    this.apiService.addProject(formData).subscribe(res => {    
      if (res.success) {
        this.router.navigate(['/user/dashboard']);
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
          this.islodder = false;
    this.isrightb = true;
    });
  }

	ngOnInit() {

    var user_data = JSON.parse(window.localStorage.getItem('user_data'));
    this.user_profile_image = user_data.profile_image;
    this.user_name = user_data.first_name;

	}
}