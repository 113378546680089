import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { MyAppSettings } from '../core/my.app.settings';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {
  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
  title = 'Register';
  registerForm: FormGroup;
  submittedregister = false;
  myFilesProfileImage:string = '';

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private route: ActivatedRoute, private toastr: ToastrService) {
    if (window.localStorage.getItem('token')) {
      this.router.navigate(['/user/dashboard']);
    }
    this.createFormRegister();
  }
    
  createFormRegister() {
    let numericRegex = /^[0-9]+$/;
    
    this.registerForm = this.formBuilder.group({
      first_name: ['', Validators.required ],
      last_name: [''],
      email_address: ['', [Validators.required, Validators.email]],
      phone_number: ['', Validators.pattern(numericRegex)],
      password: ['', [Validators.required, Validators.minLength(6)]],
      profile_image: ['']
    });
  }

  onSubmitRegister() {
    this.submittedregister = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    const formData = new FormData();

    formData.append("first_name", this.registerForm.controls.first_name.value);
    formData.append("last_name", this.registerForm.controls.last_name.value);
    formData.append("email", this.registerForm.controls.email_address.value);
    formData.append("phone_number", this.registerForm.controls.phone_number.value);
    formData.append("password", this.registerForm.controls.password.value);
    formData.append("profile_image", this.myFilesProfileImage);

    this.apiService.register(formData).subscribe(res => {
      if (res.success) {
        this.router.navigate(['/login']);
        this.toastr.success(res.message, 'Success!!');
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
    });
  }

  onFileChangeProfileImage(event) {
    this.myFilesProfileImage = '';
    this.myFilesProfileImage = event.target.files[0];
  }

  ngOnInit() {

  }
}