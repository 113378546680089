import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
//import { FacebookService, InitParams } from 'ngx-facebook';
import { MyAppSettings } from '../core/my.app.settings';

declare var paypal; 
@Component({
  selector: 'app-projectdetails',
  templateUrl: './projectdetails.component.html',
  styleUrls: []
})

export class ProjectdetailsComponent implements OnInit {

  error_msg = 'Loading...';
  is_project_load: boolean = false;
  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
  curr_url = window.location.href;

  project_slug = null;
  project_id = null;

  project_tags = 'loading...';
  is_closed = false;  
  project_doners = null;
  is_target_complete = false;

  commment_box = 'Comments';

  projectdata = null;
  handler:any = null;
  isLoggedIn: boolean = false;
  donationForm: FormGroup;
  submitteddonation = false;
  donationAmount = null;
  isdonationAmountSet: boolean = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private route: ActivatedRoute, private toastr: ToastrService, private SpinnerService: NgxSpinnerService) {
    if (window.localStorage.getItem('token')) {
      this.isLoggedIn = true;
          
    } else {
      this.isLoggedIn = false;
    }
    
    this.createDonationForm();
  }

  createDonationForm() {

    let numericRegex = /^[1-9]\d*$/;
    
    this.donationForm = this.formBuilder.group({
      donation: ['', [Validators.required, Validators.pattern(numericRegex)]],
    });
  }

  onSubmitDonation() {
    this.submitteddonation = true;

    // stop here if form is invalid
    if (this.donationForm.invalid) {
        return;
    }
    
    this.donationAmount = this.donationForm.controls.donation.value

    this.isdonationAmountSet = true;

    $("#donation-modal").modal("hide");

    this.callbackpaypal();
  }

  getProject(slug) {
    this.SpinnerService.show();
	  this.apiService.getProject(slug).subscribe(res => {
          
      if (res.success) {
        this.projectdata = res.data.campanionDetail;
        this.project_id = this.projectdata.id;
        this.project_doners = this.projectdata.project_doners;

        this.project_tags = '';
        for (var i = 0; i < this.projectdata.project_tags.length; i++) {
          this.project_tags += this.projectdata.project_tags[i].tag;
          if(i < this.projectdata.project_tags.length-1) {
            this.project_tags += ", ";
          }
        }

        if(this.projectdata.is_closed == "YES") {
          this.is_closed = true
        }

        if(this.projectdata.is_target_complete == "YES") {
          this.is_target_complete = true
        }

        this.error_msg = '';
        this.is_project_load = true;
        this.SpinnerService.hide();  
      }
    },
    error => {
      this.error_msg = error.error.message;
      this.is_project_load = false;
      this.SpinnerService.hide();
      this.toastr.error(error.error.message, 'Oops!!');
    });
  }

  pay() {
    var donationAmountobj    = this.donationAmount;
    var donationprojectid = this.project_id;

    var apiServiceobj = this.apiService;
    var toastrobj = this.toastr;
    var routerobj = this.router;

    var handler = (<any>window).StripeCheckout.configure({
//          key: 'pk_test_A3efAbNrVLjBMJa7eAiRQPnz00Fua08cnZ',
      key: this.projectdata.stripe_publishable_key,
      locale: 'auto',
      token: function (token: any) {
        console.log(token)
        toastrobj.info('Token Created!!', 'Processing!!');

        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.

        const paymentPayload = {
          amount: donationAmountobj * 100,
          currency: 'usd',
          source: token.id,
          project_id: donationprojectid
        }

        apiServiceobj.paymentStripe(paymentPayload).subscribe(res => {
          if (res.success) {
              toastrobj.success(res.message, 'Success');
              localStorage.setItem('payment_success', 'true' );
              localStorage.setItem('payment_data', JSON.stringify(res.data.payment_data) );
              localStorage.setItem('payment_from', 'stripe' );
              routerobj.navigate(['/donation/thanks']);
          }
        },
        error => {
          toastrobj.error(error.error.message, 'Oops!!');
          localStorage.setItem('payment_success', 'false' );
          routerobj.navigate(['/donation/thanks']);
        });
      }
    });
     
    handler.open({
      name: 'GiveClearly',
      description: 'Stripe Payment',
    });
  }

  callbackpaypal() {

    var apiServiceobj     = this.apiService;
    var donationAmountobj = this.donationAmount;
    var donationprojectid = this.project_id;
    var toastrobj = this.toastr;
    var routerobj = this.router;
    
    paypal.Buttons({
      style: {
        layout: 'horizontal'
      },
      createOrder: function(data, actions) {
        // This function sets up the details of the transaction, including the amount and line item details.
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: donationAmountobj
            }
          }]
        });
      },
      onApprove: function(data, actions) {
        // This function captures the funds from the transaction.
        return actions.order.capture().then(function(details) {
          // This function shows a transaction success message to your buyer.
          console.log('Transaction completed by ' + details.payer.name.given_name)

          const paymentPayload = {
            amount: donationAmountobj,
            currency: 'USD',
            source: details.purchase_units[0].payments.captures[0].id,
            project_id: donationprojectid,
            json_response: JSON.stringify(details)
          }

          apiServiceobj.paymentPayPal(paymentPayload).subscribe(res => {
              if (res.success) {
                toastrobj.success(res.message, 'Success');
                localStorage.setItem('payment_data', JSON.stringify(res.data.payment_data) );
                localStorage.setItem('payment_success', 'true' );
                localStorage.setItem('payment_from', 'paypal' );
                routerobj.navigate(['/donation/thanks']);
              }
          },
          error => {
            toastrobj.error(error.error.message, 'Oops!!');
            localStorage.setItem('payment_success', 'false' );
            routerobj.navigate(['/donation/thanks']);
          });
        });
      }
    }).render('#paypal-button-container');
  }

  loadStripe() {
    if(!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      s.onload = () => {
        /*this.handler = (<any>window).StripeCheckout.configure({
//          key: 'pk_test_A3efAbNrVLjBMJa7eAiRQPnz00Fua08cnZ',
          key: this.projectdata.stripe_publishable_key,
          locale: 'auto',
          token: function (token: any) {

          }
        });*/
      }
       
      window.document.body.appendChild(s);
    }
  }

  loadFacebook(){
    
    this.commment_box = '<div class="fb-comments" data-href="'+this.curr_url+'" data-numposts="5" data-width="100%"></div>';
    
    /*let initParams: InitParams = {
      appId: '260544101783043',
      xfbml: true,
      version: 'v2.8'
    };
 
    this.fb.init(initParams);*/
  }

  loadPayPal(){
    var ss = document.createElement( 'script' );
    ss.setAttribute( 'src', 'https://www.paypal.com/sdk/js?client-id=Ae3GcoBLu3PRQl043LHVPZZ_PaV7L5fSIjy6fosODaepTqKbTbHtYIDuG4mMDM1As3xI_Uxf_RqfYwhr' );
    document.body.appendChild( ss );
  }


	ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.project_slug = params.get('slug');
      this.getProject(this.project_slug);
    })

    this.loadStripe();
    this.loadPayPal();
//    this.loadFacebook();
	}

  setMyStyles(rrrrrrrr) {
    let styles = {
      'width': rrrrrrrr + '%',
    };
    return styles;
  }

  playVideo() {
    $('.video-modal-dialog1').on('shown.bs.modal', function () {
    var video_url = $(this).attr('data-href');
  
    $(this).find('video').attr('src',video_url);
    var myVideo: any = document.getElementById("abc_frame1");
      myVideo.play();
    })
  }

  closeVideo() {
    $('.video-modal-dialog1').on('hidden.bs.modal', function () {
      $(this).find('video').attr('src','');
    })
  }
}