import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { MyAppSettings } from '../core/my.app.settings';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: []
})

export class ResetComponent implements OnInit {
  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
  title = 'Reset Password';

  resetForm: FormGroup;
  submitted = false;
  passcode = '';

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private route: ActivatedRoute, private toastr: ToastrService) {
    if (window.localStorage.getItem('token')) {
      this.router.navigate(['/user/dashboard']);
    }
    this.createForm();
  }
    
  createForm() {
    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid

    if (this.resetForm.invalid) {
      return;
    }
    const registerPayload = {
      password: this.resetForm.controls.password.value
    }

    this.apiService.reset(registerPayload, this.passcode).subscribe(res => {
      if (res.success) {
        this.router.navigate(['/login']);
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.passcode = params.get('passcode');
    })
  }
}