import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs/index";
import { ApiResponse } from "../model/api.response";

@Injectable()

export class ApiService {
  constructor(private http: HttpClient) { }
    
  baseUrl: string = 'https://backend.clear.gives/api/';

  getBlogHTML() : Observable<{html:""}> {
    return this.http.get<{html:""}>('https://news.clear.co.com/api.php');
  }
    
  // Register User 
  register(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'register/', data);
  }

  login(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'login/', data);
  }

  socialLogin(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'login/social/', data);
  }

  registerSocialUser(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'user/social/register/', data);
  }

  checkSocialConnectOrNot() : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'user/social/');
  }

  deleteSocialUser(plateform) : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'user/social/delete/'+ plateform);
  }

  getLogInUser() : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'user/');
  }

  toDeleteAccount() : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'user/delete/');
  }

  forgot(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'password/forgot/', data);
  }

  reset(data,passcode) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'password/reset/' + passcode, data);
  }

  punctualProjects() : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'project/punctual/');
  }

  newProjects() : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'project/latest/');
  }

  getProject(slug) : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'project/'+ slug);
  }

  getProjectMedia(slug) : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'project/media/'+ slug);
  }

  donorsProjects(slug) : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'project/donors/'+ slug);
  }

  allDonationByUser() : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'user/alldonation/');
  }

  allWithdrawalByUser() : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'user/allwithdrawal/');
  }

  addOfflineDonationForm(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'user/offline_donation/add', data);
  }

  getRaisedAndDonatedAmount() : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'user/alldonation/total/');
  }

  getEmailNotifications() : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'user/email_notifications/');
  }

  changeNotificationsEmail(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'user/email_notifications/update/', data);
  }

  getAllProjects() : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'project/all/');
  }

  searchProjects(searchdata) : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'project/search/'+ searchdata);
  }

  userProjects() : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'project/user/');
  }

  closeMyProject(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'project/close/', data);
  }

  redeemMyProject(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'project/redeem/', data);
  }

  addProject(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'project/add/', data);
  }

  editProject(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'project/edit/', data);
  }

  addMoreProjectMedia(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'project/media/add', data);
  }

  changeProjectMedia(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'project/media/edit', data);
  }

  removeProjectMedia(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'project/media/remove', data);
  }

  setDisplayOrder(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'project/media/display_order', data);
  }
  
  setToDefaultMedia(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'project/media/change_default', data);
  }

  onUpdateProfileImage(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'user/profile_image/update/', data);
  }

  onUpdateAccountSetting(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'user/account/update/', data);
  }

  stripeConnect(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'user/stripe/connect/', data);
  }

  stripeDisconnect() : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'user/stripe/disconnect/');
  }

  getStripeConnect() : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'user/stripe/connect/');
  }

  paymentStripe(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'project/stripe_donate/', data);
  }

  paymentPayPal(data) : Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'project/paypal_donate/', data);
  }
}