import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { MyAppSettings } from '../core/my.app.settings';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./addproject.component.css']
})

export class MediaComponent implements OnInit {

  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;
  project_slug = null;
  media_records = null;

  addMoreProjectMediaForm: FormGroup;
  addMoreProjectMediaFile:string = '';
  addMediaShow: boolean = true;
  changeMediaShow: boolean = true;

  changeProjectMediaForm: FormGroup;
  changeMediaID: number = null;
  changeProjectMediaFile:string = '';

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private route: ActivatedRoute, private toastr: ToastrService, private SpinnerService: NgxSpinnerService) {
    this.createAddMoreProjectMediaForm();
    this.createChangeProjectMediaForm();
  }

  createAddMoreProjectMediaForm() {
    this.addMoreProjectMediaForm = this.formBuilder.group({
      media_type: ['image-file', Validators.required ],
      file_name : ['', Validators.required ],
    });
  }

  onMediaTypeChange() {
    var media_type = this.addMoreProjectMediaForm.controls.media_type.value;
    this.addMediaShow = (media_type=='image-file' || media_type=='video-file')?true:false;
  }

  onMediaTypeChange2() {
    var media_type = this.changeProjectMediaForm.controls.media_type.value;
    this.changeMediaShow = (media_type=='image-file' || media_type=='video-file')?true:false;
  }

  onChangeAddMoreProjectMedia(event) {
    this.addMoreProjectMediaFile = '';
    this.addMoreProjectMediaFile = event.target.files[0];
  }

  onChangeProjectMedia(event) {
    this.changeProjectMediaFile = '';
    this.changeProjectMediaFile = event.target.files[0];
  }

  addMoreProjectMedia() {
    this.SpinnerService.show();
    const formData = new FormData();
    formData.append('slug', this.project_slug);
    formData.append('media_type', this.addMoreProjectMediaForm.controls.media_type.value);

    if(this.addMediaShow) {
      formData.append('file_name', this.addMoreProjectMediaFile);
    } else {
      formData.append('file_name', this.addMoreProjectMediaForm.controls.file_name.value);
    }

    this.apiService.addMoreProjectMedia(formData).subscribe(res => {
      if (res.success) {
        this.SpinnerService.hide();
        this.toastr.success(res.message, 'Success!!');
        $("#add-more-modal").modal("hide");
        this.addMoreProjectMediaForm.reset();
        this.getProjectMedia(this.project_slug);
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
      this.SpinnerService.hide();  
    });
  }

  createChangeProjectMediaForm() {
    this.changeProjectMediaForm = this.formBuilder.group({
      media_type: ['image-file', Validators.required ],
      file_name : ['', Validators.required ],
    });
  }

  chooseProjectMedia(id) {
    this.changeMediaID = id;
    $("#change-media-modal").modal('show');
  }

  setToDefaultMedia(id) {
    this.SpinnerService.show();

    const formData = new FormData();
    formData.append('id', id);
    formData.append('slug', this.project_slug);

    this.apiService.setToDefaultMedia(formData).subscribe(res => {    
      if (res.success) {
        this.SpinnerService.hide();
        this.toastr.success(res.message, 'Success!!');
        this.getProjectMedia(this.project_slug);
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
      this.SpinnerService.hide();  
    });
  }

  changeProjectMedia() {
    this.SpinnerService.show();
    const formData = new FormData();
    formData.append('id', this.changeMediaID+'');
    formData.append('slug', this.project_slug);
    formData.append('media_type', this.changeProjectMediaForm.controls.media_type.value);

    if(this.changeMediaShow) {
      formData.append('file_name', this.changeProjectMediaFile);
    } else {
      formData.append('file_name', this.changeProjectMediaForm.controls.file_name.value);
    }

    this.apiService.changeProjectMedia(formData).subscribe(res => {    
      if (res.success) {
        this.SpinnerService.hide();
        this.toastr.success(res.message, 'Success!!');
        $("#change-media-modal").modal("hide");
        this.changeProjectMediaForm.reset();
        this.getProjectMedia(this.project_slug);
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
      this.SpinnerService.hide();  
    });
  }

  removeProjectMedia(id) {
    this.SpinnerService.show();

    const formData = new FormData();
    formData.append('id', id);
    formData.append('slug', this.project_slug);

    this.apiService.removeProjectMedia(formData).subscribe(res => {
      if (res.success) {
        this.SpinnerService.hide();
        this.toastr.success(res.message, 'Success!!');
        this.getProjectMedia(this.project_slug);
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
      this.SpinnerService.hide();  
    });
  }

  setDisplayOrder() {
    this.SpinnerService.show();
    const formData = new FormData();
    formData.append('slug', this.project_slug);

    var arr = [];
    for (var iii in this.media_records) {
      var num = $('#display_order'+this.media_records[iii].id).val();
      arr[this.media_records[iii].id] = num;
    }
    formData.append('display_order', JSON.stringify(arr));

    this.apiService.setDisplayOrder(formData).subscribe(res => {
      if (res.success) {
        this.SpinnerService.hide();
        this.toastr.success(res.message, 'Success!!');
        this.getProjectMedia(this.project_slug);
      }
    },
    error => {
      this.toastr.error(error.error.message, 'Oops!!');
      this.SpinnerService.hide();  
    });
  }

  getProjectMedia(slug) {
    this.SpinnerService.show();
    this.apiService.getProjectMedia(slug).subscribe(res => {
          
      if (res.success) {
        this.media_records = res.data.media_record;
        this.SpinnerService.hide();  
      }
    },
    error => {
      this.SpinnerService.hide();
      this.toastr.error(error.error.message, 'Oops!!');
    });
  }

	ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.project_slug = params.get('slug');
      this.getProjectMedia(this.project_slug);
    })
	}
}