import {Component, OnInit } from '@angular/core';
import { MyAppSettings } from '../core/my.app.settings';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./home.component.css']
})

export class SubscriptionsComponent implements OnInit {

  BACKEND_ENDPOINT = MyAppSettings.BACKEND_ENDPOINT;

  constructor() { }

  ngOnInit() {
    
  }
}
